.font_h1 {
    font-size: 2rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: lighter;
}

.font_h2 {
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
}

.font_h3 {
    font-size: 1.17rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h4 {
    font-size: 1.25rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h5 {
    font-size: 0.83rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h6 {
    font-size: 0.625rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_body {
    font-size: 1.6rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_subtitle {
    font-size: 1.4rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_button {
    font-size: 0.75rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_caption {
    font-size: 1.2rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_overline {
    font-size: 1rem;
    font-family: 'Noto Sans', sans-serif;
}

.color_primary {
    background: #ff4e01;
    color: #fff;
}

.color_secondary {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.color_button {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.color_title {
    background: transparent;
    color: #000000;
}

.page_color {
    background: #000;
    color: #fff;
}

.color_active {
    background: transparent;
    color: #ff4e01;
}

.contact_front_d {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
}

.contact_back_d .list-group {
    /* border: 1px solid #ff4e01; */
}

.contact_back_d .contact_back_d_header {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 0 0 0;
}

.contact_back_d .contact_back_d_item {
    background: #1a1a1a;
    color: #fff;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.contact_back_d .contact_back_d_item .contact_back_content span {
    color: #808080;
}

.contact_back_d.card>.list-group:last-child {
    border-radius: 0 0 0 5px;
}

.announcement_body {
    color: #fff;
}

.announcement_header {
    border-bottom: 1px solid #ff4e01;
}

.announcement_header img {
    filter: drop-shadow(1px 2px 0px black);
}

.announcement_header_title {
    color: #ff4e01;
}

.announcement_btnClose {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.header_topBar {
    background: #000;
    color: #fff;
    /* box-shadow: 1px 0px 3px 0px rgba(255, 255, 255, .3); */
    border-bottom: 1px solid #333333;
}

.header_menu {
    color: #666666
}

.header_menu .header_menu_page .header_menu_content.active {
    background: transparent;
    color: #ff4e01;
    border-bottom: 3px solid #ff4e01;
}

.banking_dropdown a li,
.claim_dropdown li {
    background: #cccccc;
    color: #666666;
}

.banking_dropdown a.active li,
.claim_dropdown a.active li {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.header_btnLogin {
    background: #000;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 1px solid #ff4e01;
    border-bottom: 1px solid #ff4e01;
    border-left: 1px solid #ff4e01;
    border-right: 1px solid #ff4e01;
}

.header_btnRegister {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnLogout {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnRestore {
    background: linear-gradient(to right, #ff4e01, #f57700);
    ;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnBanking {
    background: transparent;
    color: #fff;
    border: 1px solid #ff4e01;
    border-radius: 5px 5px 5px 5px;
}

.header_btnPromoClaim {
    background: #000;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #ff4e01
}

.header_topBar+.header_banking_info {
    background: #0c0c0c;
    color: #fff;
    /* box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 50%); */
}

.header_sideBar_topBar {
    background: #000;
    color: #fff;
    filter: drop-shadow(-3px 0px 2px rgba(255, 255, 255, .3));
}

.navbar-toggler {
    background: transparent;
    color: #fff;
}

.header_sideBar .header_sideBar_body {
    background: #161616;
    color: #fff;
}

.header_sideBar_menu .header_menu_content,
.header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {
    background: transparent;
    color: #666666;
    border-bottom: 1px solid #666666;
    margin: 0 .85rem;
}

.header_sideBar_menu .header_menu_content.active {
    background: transparent;
    color: #ff4e01;
    border-bottom: 1px solid #666666;
}

.header_banking_info .header_banking_menu_list {
    color: #999;
}

.header_banking_info .header_banking_menu_list .active {
    color: #fff;
    background: linear-gradient(to top, #ff4e01, #f57700);
    border-radius: 5px;
}

.header_topBar_wrap .header_banking_info .header_balance {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.header_banking_info .header_balance_icon {
    color: #fff;
}

#promoClaimModal label {
    color: #ff4e01;
}

#promoClaimModal .filterTab .filterTab_selection.active {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.footer {
    background: #000;
    color: #fff;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
    border-top: 1px solid #ff4e01;
}

.footer_menu_wrap {
    border-bottom: 1px dashed #fff;
}

.footer_payment_method .footer_info_img li:first-of-type img {
    filter: brightness(0) invert(1);
}

.btnCancel {
    background: transparent;
    color: #000;
    border: 1px solid #ff4e01;
    border-radius: 5px 5px 5px 5px;
}

.modal .confirm_button {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.modal .cancel_button {
    background: transparent;
    color: #000;
    border: 1px solid #ff4e01;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.tab_pills_color.nav-pills .nav-link:hover {
    color: #ff4e01;
}

.footer_help a:hover,
.header_banking_menu_list a:hover,
.header_menu_right .header_menu_page:hover,
.walletBar_selection:hover,
.banking_dropdown a li:hover,
.header_menu_page .header_text:hover,
.claim_dropdown li:hover {
    color: #ff4e01;
}

.header_menu_page .header_text:hover {
    color: #ff4e01;
}

.banking_dropdown a li:hover,
.claim_dropdown li:hover {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.scrollToTopButton {
    background: #ff4e01;
    color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    padding: .375rem .75rem;
}

.swiper .swiper-pagination-bullet {
    background: #ff4e01;
}

.alertMessage_content svg {
    color: #ff4e01;
}

.form_required_input,
.register_error_msg,
.forgotPassword_error_msg,
.forgotUsername_error_msg,
.error_msg,
.form_required,
.displayQR_row label,
.input_error_msg {
    color: #fff;
}

.login_form_reminder_title,
.register_form_reminder_title,
.forgotPassword_form_reminder_title,
.forgotUsername_form_reminder_title {
    color: red;
    font-weight: bold;
}

.forgot_content .forgot_content_username,
.forgot_content .forgot_content_password {
    color: red;
}

.btnCancelPromotion {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.btnAfterVerify {
    background: #000;
    color: #fff;
    border: 1px solid #ff4e01;
}

.announcement_header_title span {
    margin-bottom: .25rem;
}

.section_title {
    color: #fff;
}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {
    background: transparent;
    color: #fff;
    outline: 1px solid #ff4e01;
    border-radius: 5px 5px 5px 5px;
}

#gameWalletModal .game_wallet_dropdown table tbody tr {
    color: #000;
    background: #e6e6e6;
}

#promoClaimModal .filterTab {
    background: #2d2c2c;
    color: #666666;
    border-radius: 25px;
}

#promoClaimModal .filterTab .filterTab_selection {
    border: 0;
    border-radius: 25px;
}

.section_details {
    color: #4d4d4d;
}

.card_feedback .feedback_rating_wrap .feedback_rating img {
    margin-right: .25rem;
}

.header_banking_info .header_banking_menu_list span.active {
    background: transparent;
}

.header_logo img {
    height: 4rem;
}

.header_d .header_logo img {
    height: 5rem;
}

.header_point span:nth-child(2) {
    color: #fff;
}

.header_sideBar.offcanvas.offcanvas-end {
    border-left: 0;
}

.header_banking_info_left .header_balance_currency span:nth-child(2) {
    color: #ff4e01;
}

.header_bottom_row{
    color: #fff;
}

.more_menu .dropdown_more_content {
    background: #ffff;
    color: #000;
    filter: drop-shadow(2px 3px 3px rgba(0,0,0,.3));
    border-radius: 5px;
    box-shadow: none;
  }

  .btn_banking .banking_dropdown, .btn_claim .claim_dropdown{
    filter: drop-shadow(2px 3px 3px rgba(0,0,0,.3));
  }

  .btn_gamewallet .game_wallet_dropdown{
    filter: drop-shadow(2px 3px 3px rgba(0,0,0,.3));

  }

  .selectProviderContainer .game_wallet_dropdown{
    filter: drop-shadow(2px 3px 3px rgba(0,0,0,.3));

  }