.speakerAnnouncement_body {
    background: #000;
    color: #fff;
}

.main_game {
    background: transparent;
    padding: 1rem 0 2rem;
}

.main_gameSelection .main_gameSelection_menu {
    background: #1a1a1a;
    color: #666666;
    border-radius: 10px;
    margin: 5px;
    filter: drop-shadow(rgb(0,0,0) 2px 3px 3px);
}

.main_gameSelection .main_gameSelection_menu.active {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.main_game_container {
    background: transparent;
    color: #fff;
    border-radius: 10px;
    padding: 0 .5rem;
}

.main_gameSelection .main_gameSelection_menu .main_gameSelection_image {
    margin: 0.5rem;
}

#Section_gameType .Section_gameType_body {
    background: transparent;
    padding-top: 2.5rem;
}

#Section_gameType .Section_gameType_body .section_title {
    font-weight: normal;
}

#Section_gameType .card_image_wrap {
    background: rgba(0, 0, 0, 0);
    border-radius: 5px 5px 5px 5px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#Section_gameType .content_container {
    background: rgba(0, 0, 0, 0);
    border-radius: 0px 0px 5px 5px;
}

#Section_Popular_Games_We_Love .Section_Popular_Games_We_Love_body {
    background: #00013e;
    color: #fff;
}

#Section_Popular_Games_We_Love .Section_Popular_Games_We_Love_body .section_title {
    font-weight: normal;
}

#Section_Popular_Games_We_Love .card_image_wrap {
    background: rgba(0, 0, 0, 0);
    border-radius: 5px 5px 5px 5px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#Section_Popular_Games_We_Love .content_container {
    background: rgba(0, 0, 0, 0);
    border-radius: 0px 0px 5px 5px;
}

.rankingList_body {
    background: #00013e;
    color: #fff;
    padding: 1.5rem 0 1.5rem;
}

.rankingList_body .section_title {
    font-weight: normal;
}

.rankingList_table {
    background: #fff;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

.rankingList_table_header {
    background: linear-gradient(to top, #00013e, #ff4e01);
    color: #fff;
    font-weight: normal;
    border-radius: 10px 10px 10px 10px;
    margin: 0;
    padding: 1rem;
}

.rankingList_table_container .rankingList_table_header+.rankingList_table_body {
    padding: 0;
}

.rankingList_table_row {
    background: #fff;
    color: #000;
    border-bottom: 1px solid #ccc;
    margin-left: 1rem;
    margin-right: 1rem;
}

.rankingList_username {
    font-weight: bold;
}

.rankingList_info {
    color: #f90c00;
}

.rankingList_amount {
    font-weight: bold;
}

.rankingList_m_table .rankingList_tableTab {
    background: #ff4e01;
    border: 0;
    border-radius: 10px 10px 10px 10px;
    padding: 0.65rem;
}

.rankingList_tableTab .rankingList_table_selection {
    background: transparent;
    color: #999;
    border-radius: 0;
    border-right: 1px solid #999;
    padding: .75rem 1rem;
}

.rankingList_tableTab .rankingList_table_selection.active {
    background: transparent;
    color: #fff;
    border: 0;
    border-radius: 0;
    border-right: 1px solid #999;
}

.rankingList_m_table {
    background: #fff;
    border-radius: 10px;
}

.rankingList_m_table .rankingList_table {
    background: #fff;
    border-radius: 10px 10px 10px 10px;
    filter: none;
    padding: 0;
}

.rankingList_m_table .rankingList_table_body {
    margin-top: .5rem;
}

.rankingList_m_table .rankingList_table_row {
    background: #fff;
    border-radius: 0;
    padding: 1rem;
}

#Section_mainPage_promotion .Section_mainPage_promotion_body {
    background: transparent;
    color: #fff;
    padding-top: 1.5rem;
}

#Section_mainPage_promotion .Section_mainPage_promotion_body .section_title {
    font-weight: normal;
}

#Section_mainPage_promotion .card_image_wrap {
    background: transparent;
    border-radius: 5px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#Section_Service {
    background: #00013e;
    color: #fff;
    padding: 1.5rem 0 3rem;
}

#Section_Service .section_title {
    font-weight: normal;
}

.contactUs_body {
    background: transparent;
    color: #fff;
}

#contactUs_title.pageTitle {
    background: transparent;
    color: #fff;
    padding-top: 0;
    padding-bottom: 2.5rem;
}

.contact_card.card {
    background: #1a1a1a;
    color: #fff;
    border-radius: 0.8rem;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: 10px;
    padding: 1rem;
}

#contactUs_title_m {
    background: transparent;
    color: #fff;
    padding: 2rem 1.25rem 1rem;
}

.contact_container_m .contact_card.card {
    background: transparent;
    color: #fff;
    border-radius: 0;
    border-top: 0;
    border-bottom: 1px solid #808080;
    border-left: 0;
    border-right: 0;
    margin: 10px 10px 0 10px;
    padding: 0 1rem;
}

#promotion_title {
    background: transparent;
    color: #fff;
}

#Section_promoContainer .Section_promoContainer_body {
    background: transparent;
}

#Section_promoContainer .card_container {
    border-bottom: 1px dashed #fff;
    padding: 0.8rem 1.5rem;
}

#Section_promoContainer .card_image_wrap {
    border-radius: 5px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#Section_promoContainer .content_container {
    background: rgba(0, 0, 0, 0);
}

#Section_promoContainer .card_content_title {
    color: #ff4e01;
}

#Section_promoContainer .button_action1 {
    background: #000;
    color: #fff;
    border: 1px solid #ff4e01;
    border-radius: 5px 5px 5px 5px;
}

#Section_promoContainer .button_action2 {
    background: linear-gradient(to right, #ff4e01, #f57700);
    ;
    color: rgba(255, 255, 255, 1);
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

#Section_promoContainer .filterTab {
    background: #2d2c2c;
    border-radius: 25px;
    margin-top: 2rem;
    padding: 0.5rem;
    color: #666666;
}

#Section_promoContainer .filterTab .filterTab_selection {
    padding: 0.5rem 1rem;
}

#Section_promoContainer .filterTab .filterTab_selection.active {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 25px;
}

#Section_promoContainer .promotionCode_part {
    border: 1px solid #f57700;
    border-radius: 5px;
}

#Section_promoContainer .promotionCode_part label {
    background: #f57700;
    color: #fff;
    border-radius: 1px 0 0 1px;
}

#Section_promoContainer .promotionCode_part .promotionCode_content {
    background: #000;
    color: #fff;
    border-radius: 0 5px 5px 0;

}

#Section_promoContainer .promotionTag_part label {
    background: #313030;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    margin-right: .5rem;
    padding: .25rem .5rem;
}

.help_body {
    background: transparent;
    padding: 0 0 3rem;
}

#help_title {
    background: transparent;
    color: #fff;
}

.help_accordion {
    padding: 2rem 1.5rem;
}

.help_accordion .accordion-item .accordion-button {
    background: #1a1a1a;
    border-radius: 5px 5px 5px 5px;
    color: #666;
}

.help_accordion .accordion-item .accordion-button:not(.collapsed) {
    background: linear-gradient(to right, #ff4e01, #f57700);
    border: 0 solid;
    color: #fff;
}

.help_accordion .accordion-item .accordion-collapse {
    border: 0;
    border-radius: 0 0 5px 5px;
    border-top: 0;
}

.help_accordion .accordion-item .accordion-body {
    background: #1a1a1a;
    color: #fff;
    border-radius: 0 0 5px 5px;
}

.login_body {
    background: transparent;
    color: #fff;
}

#login_title_d {
    background: transparent;
    color: #ff4e01;
    font-weight: bold;
}

#login_title_m {
    background: transparent;
    color: #ff4e01;
    font-weight: normal;
}

.login_container {
    background: #0c0c0c;
    color: #fff;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);
}

.login_form .form-label {
    color: #fff;
}

.login_btnLogin {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_body {
    background: transparent;
    color: #fff;
}

#register_title_d {
    background: transparent;
    color: #ff4e01;
    font-weight: bold;
}

#register_title_m {
    background: transparent;
    color: #ff4e01;
    font-weight: normal;
}

.register_container {
    background: #0c0c0c;
    color: #fff;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);
}

.register_stepper button {
    background: #333;
    color: #fff;
}

.register_stepper .register_stepContainer.active button {
    background: #ff4e01;
    color: rgba(255, 255, 255, 1);
}

.register_stepContainer:not(:first-child):before {
    background: #333;
}

.register_stepContainer.active:not(:first-child)::before {
    background: #ff4e01;
}

.register_form .form-label {
    color: #fff;
}

.register_btnNext {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_btnBack {
    background: transparent;
    color: #fff;
    border: 1px solid #ff4e01;
    border-radius: 5px 5px 5px 5px;
}

.register_btnDepositNow {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 25px 25px 25px 25px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_btnSend {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_body {
    background: transparent;
    color: #fff;
}

#forgotPassword_title_d {
    background: transparent;
    color: #ff4e01;
    font-weight: bold;
}

#forgotPassword_title_m {
    background: transparent;
    color: #ff4e01;
    font-weight: normal;
}

.forgotPassword_container {
    background: #0c0c0c;
    color: #fff;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);
}

.forgotPassword_form .form-label {
    color: #fff;
}

.forgotPassword_btnNext {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_btnBack {
    background: transparent;
    color: #fff;
    border: 1px solid #ff4e01;
    border-radius: 5px 5px 5px 5px;
}

.forgotPassword_btnSubmit {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_btnSend {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotUsername_body {
    background: transparent;
    color: #fff;
}

#forgotUsername_title_d {
    background: transparent;
    color: #ff4e01;
    font-weight: bold;
}

#forgotUsername_title_m {
    background: transparent;
    color: #ff4e01;
    font-weight: normal;
}

.forgotUsername_container {
    background: #0c0c0c;
    color: #fff;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);
}

.forgotUsername_form .form-label {
    color: #fff;
}

.forgotUsername_btnSubmit {
    background: linear-gradient(to right, #ff4e01, #f57700);
    ;
    color: #00013e;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotUsername_btnSend {
    background: linear-gradient(to right, #ff4e01, #f57700);
    ;
    color: #00013e;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.myProfile_body {
    background: transparent;
    color: #fff;
}

#myProfile_title {
    background: transparent;
    color: #fff;
}

.myProfile_mySetting_row {
    background: #161616;
}

.myProfile_myAccount_row {
    background: #161616;
}

.myProfile_myAccount_row svg {
    color: #fff;
}

.slots_body {
    background: transparent;
}

#slot_title {
    background: transparent;
    color: #fff;
    font-weight: bold;
}

.slots_body .title_container a+div select {
    border-radius: 20px;
}

.gameProviderTab_container {
    background: transparent;
    border-radius: 5px 5px 5px 5px;
}

.gameProviderTab .gameProviderTab_selection {
    background: linear-gradient(0deg, #ff4e01, #f57700);
    border-radius: 5px 5px 5px 5px;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    filter: opacity(0.5);
}

.gameProviderTab .gameProviderTab_selection img{
    filter: opacity(0.5) grayscale(1);
}

.gameProviderTab .gameProviderTab_selection.active {
    background: #fff;
    color: rgb(0, 0, 0, 1);
    border: 1px solid #ff4e01;
}

.gameProviderTab .gameProviderTab_selection.active img{
    filter: none;
}


.gameProviderTab_container button {
    background: transparent;
    color: #fff;
}

.slotTypeTab {
    background: #2d2c2c;
    border-radius: 5px 5px 5px 5px;
    color: #666666;
}

.slotTypeTab .slotTypeTab_selection.active {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
}

#slotGameContainer .button_action1 {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

#slotGameContainer .card_image_wrap {
    border-radius: 5px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#slotGameContainer .content_container {
    background: rgba(255, 255, 255, 0);
    color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.mslot_container {
    margin: 4rem 10rem;
}

.mslot_container_m {
    margin: 0 5rem 1rem;
}

.mslot_container .mslot_wrap {
    background: #0c0c0c;
    color: #fff;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 1);
    ;
    padding: 3.5rem 2rem;
}

.mslot_container_m .mslot_wrap {
    background: transparent;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    padding: 1.5rem;
}

.mslot_userInfo label {
    color: #ff4e01;
}

#mslotGameContainer .mslotGameContainer_body {
    background: #00013e;
    padding-top: 1.5rem;
}

#Section_1675926801290 .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#mslotGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#casinoGameContainer .casinoGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#casinoGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#casinoGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#sportGameContainer .sportGameContainer_body {
    background: transparent;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
    padding-top: 1.5rem;
}

#sportGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#sportGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#esportGameContainer .sportGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#esportGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#esportGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#fishingGameContainer .fishingGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#fishingGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#fishingGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#lotteryGameContainer .lotteryGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#lotteryGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#lotteryGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#boardGameContainer .boardGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#boardGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#boardGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#othersContainer .othersContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#othersContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#othersContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

.wallet_body {
    background: transparent
}

.wallet_body .pageTitle {
    background: transparent;
    color: #fff;
}

.wallet_container_title {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
}

.walletBar {
    background: #0c0c0c;
    color: #fff;
    filter: drop-shadow(2px 3px 3px rgb(0, 0, 0, 1));
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}

.walletBar_userInfo {
    color: #fff;
}

.walletBar_userVerification {
    background: #161616;
}

.walletBar_selection.active {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 5px;
}

.wallet_container {
    border: 0;
    border-radius: 10px 10px 10px 10px;
    filter: drop-shadow(2px 3px 3px rgb(0, 0, 0, 1));
}

.wallet_container_wrap {
    background: #0c0c0c;
    color: #fff;
    border-radius: 0 0 10px 10px;
}

.wallet_depositMethod img+div,
.deposit_channel_row img+div {
    color: #a6a8ab;
}

.wallet_depositMethod img+div.active,
.deposit_channel_row img+div.active {
    color: #fff;
}

.wallet_btnSubmit,
.wallet_gameWallet_btnAllIn,
.wallet_gameWallet_btnRefresh {
    color: linear-gradient(to right, #ff4e01, #f57700);
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.wallet_container .form-control:disabled {
    background: #808080;
    color: rgba(0, 0, 0, 1);
}

.wallet_reminder_container {
    background: #161616;
    color: #fff;
}

.wallet_reminder_title {
    color: red;
}

.table.wallet_history_table thead,
.myAccount_notification_header,
.myAccount_bankingDetails_tableHeader {
    background: linear-gradient(to top, #ff4e01, #f57700);
    color: rgba(255, 255, 255, 1);
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
}

.table.wallet_history_table tbody,
.myAccount_notification_title_row,
.myAccount_bankingDetails_table {
    background: #f2f2f2;
    color: rgba(0, 0, 0, 1);
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.wallet_btnAmount button {
    background: #161616;
    color: #fff;
    border: 0;
    filter: opacity(.5);
}

.wallet_btnAmount button.active {
    background: #161616;
    color: #fff;
    border: 1px solid #ff4e01;
    filter: opacity(1);

}

.myAccount_notification_content {
    background: #fff;
    color: #000;
}

.myAccount_changePasswordTab .myAccount_changePasswordTab_selection.active {
    background: red;
    color: #fff;
}

.myAccount_userInfo_profile label {
    color: #ff4e01;
}

.transactionTypeTab {
    background: #2d2c2c;
    border-radius: 25px;
    padding: 0.5rem;
    color: #666666;
}

.transactionTypeTab .transactionType_selection {
    background: transparent;
    color: #666666;
    border-radius: 25px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
    margin-right: 1rem;
    padding: 0.75rem 1rem;
}

.transactionTypeTab .transactionType_selection.active {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 25px;
}

.wallet_body_container_m {
    padding: 0;
}

.wallet_container_m {
    color: #fff;
    border: 0;
    filter: none;
}

.wallet_container_m .wallet_container_wrap {
    background: transparent;
}

.wallet_container_m .wallet_reminder_container {
    background: transparent;
}

.walletBar_btnLogout {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px none;
    border-bottom: 0px none;
    border-left: 0px none;
    border-right: 0px none;
}

.depositBankImg img {
    border-radius: .375rem;
    border-top: 1px solid #ff4e01;
    border-bottom: 1px solid #ff4e01;
    border-left: 1px solid #ff4e01;
    border-right: 1px solid #ff4e01;
}

.coupon_body {
    background: transparent;
    padding: 0 0 3rem;
}

#coupon_title {
    background: transparent;
    color: #fff;
}

#coupon .filterTab {
    background: #2d2c2c;
    border-radius: 25px;
    margin-top: 2rem;
    padding: 0.5rem;
    color: #666666;
}

#coupon .filterTab .filterTab_selection {
    padding: 0.5rem 1rem;
}

#coupon .filterTab .filterTab_selection.active {
    background: linear-gradient(to right, #ff4e01, #f57700);
    color: #fff;
    border-radius: 25px;
}

.coupon_content_wrapper {
    color: #fff;
}

.coupon_body_container {
    color: rgba(0, 0, 0, 1);
}

.button_couponClaim {
    border-radius: 5px 5px 5px 5px;
    padding: 0.5rem;
}

.coupon_expireTime {
    color: red;
    margin-bottom: 1rem;
}

.coupon_tnc {
    color: #0d6efd;
}

.button_claimCoupon {
    border-radius: 5px;
    margin-top: .75rem;
    padding: .5rem;
}


.App,
.page {
    background: linear-gradient(to bottom, #161616, #000000)
}

.walletBar_walletUserQrInfo {
    background: #161616;
}

.shareLink_button {
    background: #000;
    border: 1px solid #ff4e01;
    border-radius: 5px;
    color: #fff;
}


.count_box p:first-of-type,
.count_container span {
    background: #1a1a1a;
    color: #fff
}

.contact_card.card span {
    color: #808080;
}

.walletBar_selection {
    color: #666666;
}

.myQRCode_body .shareQR_info {
    background: #0c0c0c;
    color: #fff;
    filter: drop-shadow(2px 4px 6px black);
}


#myQRCode_title {
    background: transparent;
}

.shareQR_shareMethodWrap {
    color: #fff;
}

.shareQR_shareMethodWrap .shareQR_title{
    color: #ff4e01;
}

.feedback_info_wrap {
    padding: 1.25rem;
    padding-bottom: 0;
}

.feedback_username {
    font-size: 1.15rem;
    color: #ff4e01;
    font-weight: 600;
}

.feedback_subject {
    font-size: 1.10rem;
    color: #ff4e01;
    font-weight: 600;
    padding-bottom: .5rem
}

.feedback_desc {
    font-size: .85rem;
    /* padding: 1rem;*/
    margin: 1rem 0;
}

.feedback_user_wrap {
    padding-bottom: .85rem
}

.card_feedback {
    filter: drop-shadow(2px 4px 6px black);
    background: #1a1a1a;
    color: #808080;
    border-radius: 5px;
}

.feedback_body {
    color: #fff;
    margin-bottom: 1.5rem;
}


.vip_joinNowContent_title{
   color: #fff;

  }
  
  .vip_joinNowContent_subtitle {
  color: #808080;
  }
  
  .vip_joinNowContent_button button{
    background: linear-gradient(to right, #ff4e01, #f57700);
  color: #fff;
  border-radius: 5px;
  }




   .feedback_body .section_title{
font-weight: 400;
    }

    .Section_HOT_GAMES_body .section_title{
        font-weight: 400;
            }
        

#Section_HOT_GAMES .Section_HOT_GAMES_body  {
    color: hsl(0, 0%, 100%);
}
    #Section_HOT_GAMES  .card_image_wrap {
        background: transparent;
        border-radius: 5px;
        filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
    }


.myFriend_body .btnSearch{
    background: #333333;
}


.friendList_group_title{
    background: #161616;
    color: #fff;
}

.friendList_group_item {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #4d4d4d;
  }

  .friendList_group:not(:last-child) .friendList_group_item_container .friendList_group_item:last-of-type {
    border-bottom: none;
  }

  .friendList_info_phone,.friendList_info_status,.friendDetail_info_phone,.friendDetail_info_status{
    color: #4d4d4d;
  }
  .friendRequest_tab {
    background:  #2d2c2c;
    border-radius: 5px;
    color: #666666;
}


  .friendRequest_tab .friendRequestTab_selection {
    border: 0;
    border-radius: 5px;
}


.friendRequest_tab .friendRequestTab_selection.active {
    background: linear-gradient(90deg,#ff4e01,#f57700);
    color: #fff;
}

.friendRequest_tab .friendRequestTab_selection:hover {
    border: 0;
    border-radius: 5px;
    /* color: #000; */
}

.friendDetail_group_item {

    border: 1px solid #4d4d4d;

  
  }

  .searchFriend_container{
    border: 1px solid #4d4d4d;
  }


  .main_game_container a{
    padding: 0 .5rem .25rem;
  }


  .gameWallet_accordion .gameWallet_accordion_icon{
    background: #000;
    color: #fff;
  }


  .friendDetail_info_phone, .friendDetail_info_status, .friendList_info_phone, .friendList_info_status{
    color: #fff;
  }


  #GameTermsModal .modal-header{
    background: linear-gradient(90deg,#ff4e01,#f57700);
    color: #fff;
  }

  #GameTermsModal .modal-header .btn-close{
    background: transparent var(--btn-close-bg-white) center/1em auto no-repeat;
  }